@tailwind base;
@tailwind components;
@tailwind utilities;

/* src/index.css or your global CSS file */
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700&display=swap');

body {
  font-family: 'Inter', sans-serif;
}

input::placeholder {
  color: #9ca3af;
  /* Tailwind Gray 400 */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* For Chrome, Safari, and Opera */
::-webkit-scrollbar {
  width: 8px;
  /* Width of the scrollbar */
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
  /* Color of the track (background of the scrollbar) */
}

::-webkit-scrollbar-thumb {
  background: #888;
  /* Color of the thumb (the draggable part of the scrollbar) */
}